import React from "react";

export default function SectMetodologias() {
  return (
    <div data-figma-id="604:2433" class="nosotros-metodologia">
      <p data-figma-id="576:2286" class="nosotros-metodologia-texto">
        Nuestra Metodología
      </p>
      <div data-figma-id="576:2287" class="nosotros-metodologia-tarjetas">
        <div data-figma-id="604:2452" class="metodologia-tarjeta">
          <div data-figma-id="604:2453" class="metodologia-tarjeta-info">
            <img
              src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/6757801704a10f41fdc2e314_iconos-tarjetas-metologia.svg"
              data-figma-id="604:2459"
              class="img-27"
            />
            <p data-figma-id="604:2455" class="metodologia-tarjeta-info-texto">
            Creamos soluciones centradas en el usuario mediante empatía e innovación.​
            </p>
          </div>
          <div data-figma-id="604:2456" class="metodologia-tarjeta-titulo">
            <p
              data-figma-id="604:2457"
              class="metodologia-tarjeta-titulo-texto"
            >
              Design Thinking
            </p>
          </div>
        </div>
        <div data-figma-id="604:2461" class="metodologia-tarjeta">
          <div data-figma-id="604:2462" class="metodologia-tarjeta-info">
            <img
              src="images/iconos-tarjetas-metologia.png"
              data-figma-id="604:2459"
              class="img-27"
            />
            <p data-figma-id="604:2464" class="metodologia-tarjeta-info-texto">
            Validamos y convertimos ideas rápidamente con prototipos, minimizando riesgos y garantizando relevancia.​
            </p>
          </div>
          <div data-figma-id="604:2465" class="metodologia-tarjeta-titulo">
            <p
              data-figma-id="604:2466"
              class="metodologia-tarjeta-titulo-texto"
            >
              Lean Startup
            </p>
          </div>
        </div>
        <div data-figma-id="604:2443" class="metodologia-tarjeta">
          <div data-figma-id="604:2444" class="metodologia-tarjeta-info">
            <img
              src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/67578016eb8527aed45802ca_iconos-tarjetas-metologia.svg"
              data-figma-id="604:2450"
              class="img-27"
            />
            <p data-figma-id="604:2446" class="metodologia-tarjeta-info-texto">
            Organizamos el desarrollo en sprints, promoviendo la colaboración y las entregas de forma incremental.​
            </p>
          </div>
          <div data-figma-id="604:2447" class="metodologia-tarjeta-titulo">
            <p
              data-figma-id="604:2448"
              class="metodologia-tarjeta-titulo-texto"
            >
              Scrum
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
