import React from 'react'

export default function SecLograrJuntos() {
  return (
    <div class="nosotros-info-4">
          <div class="nostros-info-4-titulo">
            <div class="nosotros-info-4-titulo-texto">¿Qué podemos lograr juntos?</div>
            <i class="fi fi-rr-arrow-right vectors-wrapper"></i>
          </div>
          <div class="nosotros-info-4-contenido">
            <div class="nosotros-info-4-contenido-1">Transformamos ideas en soluciones tecnológicas que escalan y evolucionan con las necesidades de nuestros clientes. Con nosotros, no solo obtendrás un producto final, sino un socio comprometido en maximizar el valor de tu inversión.</div>
            <div class="nosotros-info-4-contenido-2">Déjanos ser el motor de tu próxima gran innovación tecnológica.</div>
          </div>
        </div>
  )
}
