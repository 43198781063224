import React, { useContext } from "react";
import { WindowSizeContext } from "../../../../WindowSizeContext";

export default function SectRolesEquipo() {
  const { width, height } = useContext(WindowSizeContext);

  const mRenderNoCelular = () => {
    return (
      <div class="nosotros-equipo" id="sec-equipo">
          <div class="nosotros-equipo-titulos">
            <div class="nosotros-equipo-titulo">Nuestro Equipo</div>
            <div class="nosotros-equipo-info-1-titulo">Tenemos un equipo dedicado a apoyarlo a usted y a su negocio</div>
          </div>
          <div class="nosotros-equipo-info">
            <div class="linea-divisora-verde-2"></div>
            <div class="nosotros-equipo-apartados">
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f6e48ec5542028ae2e_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Manager</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f7a5c83d0a73c19cca_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Líder Técnico</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f7d374cf38a1682c0a_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Project Manager</div>
              </div>
            </div>
            <div class="linea-divisora-verde-2"></div>
            <div class="nosotros-equipo-apartados">
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f8046d2a5ac74737c7_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Desarrollador Full Stack</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f8d7e4e78149abf947_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Diseñadora UX/UI</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f9e70d0efc146c431f_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Desarrollador Cloud</div>
              </div>
            </div>
            <div class="linea-divisora-verde-2"></div>
          </div>
          <div class="texto-nuestroequipo-final">Conformado por profesionales altamente capacitados, con experiencia en una amplia gama de disciplinas tecnológicas. Esto nos permite abordar proyectos desde una perspectiva integral, utilizando las mejores prácticas y estándares de la industria.
          </div>
        </div>
    );
  };
  const mRenderCelular = () => {
    return (
      <div class="nosotros-equipo-cel" id="sec-equipo">
          <div class="nosotros-equipo-titulos">
            <div class="nosotros-equipo-titulo">Nuestro Equipo</div>
            <div class="nosotros-equipo-info-1-titulo">Tenemos un equipo dedicado a apoyarlo a usted y a su negocio</div>
          </div>
          <div class="nosotros-equipo-info">
            <div class="linea-divisora-verde-2"></div>
            <div class="nosotros-equipo-apartados">
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f6e48ec5542028ae2e_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Manager</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f7a5c83d0a73c19cca_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Líder Ténico</div>
              </div>
            </div>
            <div class="linea-divisora-verde-2"></div>
            <div class="nosotros-equipo-apartados">
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f7d374cf38a1682c0a_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Project Manager</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f8046d2a5ac74737c7_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Desarrollador Full Stack</div>
              </div>
            </div>
            <div class="linea-divisora-verde-2"></div>
            <div class="nosotros-equipo-apartados">
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f8d7e4e78149abf947_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Diseñadora UX/UI</div>
              </div>
              <div class="linea-divisora-vertical"></div>
              <div class="nosotros-equipo-tarjeta">
                <div class="nosotros-equipo-tarjeta-foto">
                  <div class="roles"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a49f9e70d0efc146c431f_Untitled-design-(1)-1.png" loading="lazy" width="138" height="138" alt="" class="untitled-design-1-1"/></div>
                </div>
                <div class="nosotros-equipo-tarjeta-puesto">Desarrollador Cloud</div>
              </div>
            </div>
            <div class="linea-divisora-verde-2"></div>
          </div>
          <div class="texto-nuestroequipo-final">Conformado por profesionales altamente capacitados, con experiencia en una amplia gama de disciplinas tecnológicas. Esto nos permite abordar proyectos desde una perspectiva integral, utilizando las mejores prácticas y estándares de la industria.
          </div>
        </div>
    );
  };

  return (
    <>
      {width < 768 ? mRenderCelular() : mRenderNoCelular()}
    </>
  );
}
