import React, { useContext, useEffect, useState } from "react";
import { WindowSizeContext } from "../../../../WindowSizeContext";
import { video } from "framer-motion/client";

export default function SectNuestrosServicios() {
  const { width, height } = useContext(WindowSizeContext);
  const [vIdCartaActiva, setVIdCartaActiva] = useState(null);
  const [vAnchoMenu, setVAnchoMenu] = useState(0);

  var vServicios = [
    {
      id: 1,
      titulo: "Gen AI & LLM’s",
      resumen:
        "Repositorio inteligente de archivos con integración directa con LLM’s (Documentos, Reportes, Transcripciones de Reuniones, etc) para Insights y búsqueda de contenido.",
      contenido:
        "Repositorio inteligente de archivos, integrado con modelos de lenguaje avanzados (LLMs), diseñado para proporcionar insights precisos, realizar búsquedas avanzadas para potenciar la productividad y optimizar la toma de decisiones.",
      url_img: "GenAi.png",
      url_img_cel: "GenAiMovil.png",
      icono: "fi fi-tr-head-side-brain",
    },
    {
      id: 2,
      titulo: "FinOps",
      resumen:
        "Dashboard para monitoreo y notificación de consumo/costos de servicios en la nube.​",
      contenido:
        "Nuestra experiencia en FinOps permite a las organizaciones monitorear y optimizar en tiempo real los consumos y costos de servicios en la nube. Diseñado para ofrecer una experiencia intuitiva y accesible, no requiere conocimientos avanzados de nube, proporcionando visibilidad completa de los recursos y gastos de tu organización.",
      url_img: "FinOps.png",
      url_img_cel: "FinOpsMovil.png",
      icono: "fi fi-tr-cloud-share",
    },
    {
      id: 3,
      titulo: "Report Builder",
      resumen:
        "Nuestra experiencia permite crear reportes automatizados con plantillas personalizables, integrados fuentes de datos para generar informes precisos y actualizados en tiempo real.",
      contenido:
        "Nuestro experiencia en Report Builder permite a las empresas crear reportes automatizados utilizando plantillas visuales personalizables. Con capacidad de integración directa con CRMs y bases de datos, tenemos la capacidad de crear aplicativos que recopilan información automáticamente para generar reportes precisos y actualizados en tiempo real.",
      url_img: "Report Builder.png",
      url_img_cel: "Report Builder Movil.png",
      icono: "fi fi-tr-file-invoice",
    },
    {
      id: 4,
      titulo: "Lakehouse",
      resumen:
        "Creamos Lagos de Datos escalables con pipelines automatizados para análisis, usando distintos proveedores como AWS, Azure, Databricks, etc.",
      contenido:
        "Nuestra experiencia en la construcción de Lagos de Datos combina el poder de un data lake y un data warehouse para crear ecosistemas de datos escalables y eficientes. Diseñamos y gestionamos pipelines de datos para ingesta, transformación y automatización de procesos analíticos, aprovechando plataformas líderes como AWS, Azure y Databricks.",
      url_img: "Lakehouse.png",
      url_img_cel: "LakehouseMovil.png",
      icono: "fi fi-tr-system-cloud",
    },
    {
      id: 5,
      titulo: "MLOps",
      resumen:
        "Fomentamos ecosistemas en la nube con CI/CD para desarrollar, probar y desplegar modelos de forma ágil y confiable.",
      contenido:
        "Esta cultura operativa permite a las empresas crear ecosistemas en la nube donde pueden desarrollar, probar y versionar modelos de manera eficiente. Utilizando pipelines de CI/CD, facilitamos la promoción de modelos desde desarrollo hasta entornos de testeo y producción, asegurando un despliegue confiable y ágil.",
        url_img: "GenAi.png",
        url_img_cel: "GenAiMovil.png",
      icono: "fi fi-tr-cloud-gear-automation",
    },
    {
      id: 6,
      titulo: "UX/UI Development",
      resumen:
        "Diseñamos aplicaciones con interfaces intuitivas, centradas en el usuario, e implementadas en entornos cloud escalables y de alto rendimiento.",
      contenido:
        "Uno de los pilares de nuestra esencia abarca el diseño y desarrollo de aplicaciones completas con interfaces intuitivas y experiencias centradas en el usuario. Estas aplicaciones son implementadas en entornos cloud elásticos, robustos y escalables, garantizando un rendimiento óptimo y una experiencia superior.",
        url_img: "GenAi.png",
        url_img_cel: "GenAiMovil.png",
      icono: "fi fi-tr-customize-computer",
    },
  ];

  useEffect(() => {
    if (width < 768) {
      if (vIdCartaActiva === null) {
        setVIdCartaActiva(vServicios[0].id);
        setVAnchoMenu(275);
      }
    }
  }, [width]);

  const mTarjetaSeleccionada = (vIdTarjeta) => {
    setVIdCartaActiva(vIdTarjeta);
    setTimeout(() => {
      setVAnchoMenu(275);
    }, 250);
  };

  const mRenderMenuServicios = () => {
    if (width < 768 && vIdCartaActiva !== null) {
      return (
        <BtnServicioCel
          vServicios={vServicios}
          vIdCartaActiva={vIdCartaActiva}
          mTarjetaSeleccionada={mTarjetaSeleccionada}
          vAnchoMenu={vAnchoMenu}
        />
      );
    } else {
      if (vIdCartaActiva !== null)
        return (
          <div
            data-figma-id="514:1497"
            class="servicios-apartados"
            style={{
              minWidth: vAnchoMenu,
              maxWidth: vAnchoMenu,
            }}
          >
            {vServicios.map((vServicio, vId) => {
              if (vId < 9) {
                // Mostrar los primeros 5 elementos
                return (
                  <BtnServicio
                    vServicio={vServicio}
                    key={vServicio.id + vServicio.titulo}
                    vIdCartaActiva={vIdCartaActiva}
                    mTarjetaSeleccionada={mTarjetaSeleccionada}
                    vId={vServicio.id}
                  />
                );
              }
              return null; // No renderiza nada para índices mayores a 4
            })}

            {vServicios.length > 9 && (
              <div data-figma-id="514:1506" class="boton-ver-todos-2">
                <div data-figma-id="514:1507" class="ver-todos-boton-info">
                  <div data-figma-id="514:1508" class="frame-18">
                    <p data-figma-id="514:1509" class="ver-todos-boton-texto">
                      Ver todos
                    </p>
                    <i class="fi fi-rr-arrow-small-right img-18"></i>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
    }
  };

  const mRenderContendio = (vIdCartaActiva) => {
    return (
      <div data-figma-id="345:794" class="servicios-contenido" style={vIdCartaActiva !== null
        ? {height:"auto",minHeight:"auto"}
        : {}}>
        <div
          data-figma-id="345:795"
          className={
            vIdCartaActiva !== null
              ? "servicios-contenido-info"
              : "servicios-contenido-2"
          }
        >
          {mRenderMenuServicios()}
          {vIdCartaActiva !== null ? (
            <TarjetaServicio
            vServicio={vServicios.find((item) => item.id === vIdCartaActiva)}
            key={"btn-" + vServicios.find((item) => item.id === vIdCartaActiva).id + vServicios.find((item) => item.id === vIdCartaActiva).titulo}
            vIdCartaActiva={vIdCartaActiva}
            mTarjetaSeleccionada={mTarjetaSeleccionada}
            vId={vServicios.find((item) => item.id === vIdCartaActiva).id}
          />
          ) : (
            vServicios.map((vServicio, vId) => (
              <TarjetaServicio
                vServicio={vServicio}
                key={"btn-" + vServicio.id + vServicio.titulo}
                vIdCartaActiva={vIdCartaActiva}
                mTarjetaSeleccionada={mTarjetaSeleccionada}
                vId={vServicio.id}
              />
            ))
          )}
        </div>
        {vIdCartaActiva === null && (
          <>
            <div data-figma-id="345:800" class="linea-divisora"></div>
            <div data-figma-id="345:801" class="boton-ver-todos">
              <p data-figma-id="345:802" class="boton-ver-todos-texto">
                Ver todos
              </p>
              <i class="fi fi-rs-arrow-right img-7"></i>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div data-figma-id="345:850" class="home-info-5-servicios">
      <div class="div-block-3">
        <p data-figma-id="345:748" class="servicios-titulo">
          Nuestro Servicios
        </p>
        {mRenderContendio(vIdCartaActiva)}
      </div>
    </div>
  );
}

function TarjetaServicio({
  vIdCartaActiva,
  mTarjetaSeleccionada,
  vId,
  vServicio,
}) {
  const [vMostrar, setVMostrar] = useState(true);
  const [vMostrar2, setVMostrar2] = useState(true);
  const { width, height } = useContext(WindowSizeContext);

  useEffect(() => {
    setVMostrar(true);
    setVMostrar2(true);
    if (!(vId == vIdCartaActiva || vIdCartaActiva === null)) {
      setVMostrar2(false);
      setTimeout(() => {
        setVMostrar(false);
      }, 200);
    }
  }, [vIdCartaActiva]);

  return (
    <div
      data-figma-id="345:837"
      className={
        (vIdCartaActiva !== null) & (vId == vIdCartaActiva)
          ? "servicios-info"
          : "servicio-tarjeta"
      }
      onClick={() => mTarjetaSeleccionada(vId)}
      style={
        vMostrar
          ? {
              width: vMostrar2 ? "100%" : "0px",
              overflow: "hidden",
              transition: !vMostrar2 ? "all .2s" : "none",
            }
          : {
              display: "none",
            }
      }
    >
      {vIdCartaActiva === null || vId != vIdCartaActiva ? (
        <>
          <div
            data-figma-id="345:838"
            class="servicio-tarjeta-imagen"
            style={{
              backgroundImage: "url('images/img-card-servicios.jpg')",
              backgroundPosition: "50%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minWidth: vMostrar2 ? 293 : 0,
              width: vMostrar2 ? 293 : 0,
              overflowX: "hidden",
            }}
          >
            <div data-figma-id="345:846" class="iconos-servicios">
              <i class={`${vServicio.icono} img-19`}></i>
            </div>
          </div>
          <div
            data-figma-id="345:840"
            class="servicio-tarjeta-contenido"
            style={{
              minWidth: vMostrar2 ? 293 : 0,
              width: vMostrar2 ? 293 : 0,
              padding: vMostrar2 ? 20 : 0,
              overflow: "hidden",
            }}
          >
            <div
              data-figma-id="345:841"
              class="servicio-tarjeta-contenido-titulo"
            >
              <p
                data-figma-id="345:842"
                class="servicio-tarjeta-contenido-titulo-1"
              >
                Servicios Cloudih
              </p>
              <p
                data-figma-id="345:843"
                class="servicio-tarjeta-contenido-titulo-2"
              >
                {vServicio.titulo}
              </p>
            </div>
            <p data-figma-id="345:844" class="servicio-tarjeta-contenido-info">
              {vServicio.resumen}
            </p>
          </div>
        </>
      ) : (
        <>
          <div data-figma-id="351:754" class="servicios-info-titulo-flecha">
            <p data-figma-id="351:755" class="servicios-info-titulo-texto">
              {vServicio.titulo}
            </p>
            <i class="fi fi-rr-arrow-up-right img-15"></i>
          </div>
          <div data-figma-id="351:757" class="contenido">
            <p data-figma-id="351:758" class="servicios-info-texto">
              {vServicio.contenido}
            </p>
            <div class="div-block-5"><img src={`images/${width<768?vServicio.url_img_cel:vServicio.url_img}`} loading="lazy"  alt="" class="img-servicio-info"/></div>
          </div>
        </>
      )}
    </div>
  );
}

function BtnServicio({ vIdCartaActiva, mTarjetaSeleccionada, vId, vServicio }) {
  return (
    <div data-figma-id="351:791" class="boton-servicios">
      <div
        data-figma-id="351:792"
        class={`boton-servicio${vId === vIdCartaActiva ? "-seleccionado" : ""}`}
        onClick={() => mTarjetaSeleccionada(vId)}
      >
        {vId === vIdCartaActiva && <i class="fi fi-ss-circle img-16"></i>}

        <p
          data-figma-id="351:794"
          class={`servicio-1-texto${vId === vIdCartaActiva ? "" : "-normal"}`}
        >
          {vServicio.titulo}
        </p>
        <i class="fi fi-rr-angle-small-down img-17"></i>
      </div>
    </div>
  );
}

function BtnServicioCel({
  vServicios,
  vIdCartaActiva,
  mTarjetaSeleccionada,
  vAnchoMenu,
}) {
  const [vAltoMenu, setVAltoMenu] = useState(68 * vServicios.length-((vServicios.length-1)*20));
  const [vServicio, setvServicio] = useState(
    vServicios.find((item) => item.id === vIdCartaActiva)
  );

  const [vIsSeleccionar, setvIsSeleccionar] = useState(false);

  useEffect(() => {
    setvServicio(vServicios.find((item) => item.id === vIdCartaActiva));
  }, [vIdCartaActiva]);

  return (
    <div
      class="servicios-apartados-cel"
      style={
        vIsSeleccionar
          ? { height: vAltoMenu, minHeight: vAltoMenu, borderRadius: "20px" }
          : {height: 65, minHeight: 65}
      }
      onClick={() => {
        if (!vIsSeleccionar) {
          setvIsSeleccionar(true);
        }
      }}
    >
      {!vIsSeleccionar ? (
        <div data-figma-id="351:792" class="item-servicio">
          {vServicio.id === vIdCartaActiva && (
            <i class="fi fi-ss-circle img-16"></i>
          )}
          <p
            data-figma-id="351:794"
            class="servicio-1-texto-cel"
            style={{
              maxWidth: "calc(100% - calc( 16px + 10px) - calc( 20px + 10px) )",
            }}
          >
            {vServicio.titulo}
          </p>
          {!vIsSeleccionar && <i class="fi fi-rr-angle-small-down img-17"></i>}
        </div>
      ) : (
        vServicios.map((vServicio, index) => (
          <div
            data-figma-id="351:792"
            class="item-servicio"
            onClick={() => {
              if (vIdCartaActiva !== vServicio.id) {
                mTarjetaSeleccionada(vServicio.id);
              }
              setvIsSeleccionar(false);
            }}
          >
            {vServicio.id === vIdCartaActiva && (
              <i class="fi fi-ss-circle img-16"></i>
            )}
            <p data-figma-id="351:794" class="servicio-1-texto-cel" style={vServicio.id === vIdCartaActiva?{
              maxWidth: "calc(100% - calc( 16px + 10px) )",
            }:{maxWidth:"100%"}}>
              {vServicio.titulo}
            </p>
            {!vIsSeleccionar && (
              <i class="fi fi-rr-angle-small-down img-17"></i>
            )}
          </div>
        ))
      )}
    </div>
  );
}
