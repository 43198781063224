/*
====================================================================
                        Componente App
====================================================================
Este módulo define el componente principal de la aplicación que gestiona 
las rutas y la navegación utilizando `react-router-dom`.

Funcionalidades clave:
- Gestiona las rutas de la aplicación, incluyendo las necesarias para 
  interacción básica de la aplicación.
- Permite la navegación entre diferentes páginas de la aplicación.
- Maneja rutas no encontradas con un mensaje 404, mostrando una 
  página de error si el usuario navega a una ruta inexistente.
====================================================================
*/

// ------------------------------------------------------------------
//                            IMPORTS
// ------------------------------------------------------------------
// Importación de librerías externas y componentes internos necesarios.

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SobreNosotros from "./pages/SobreNosotros"; // Ejemplo de un componente adicional
import ScrollToTop from "./hooks/ScrollToTop"; // Ejemplo de un componente adicional
import ScrollToSection from "./hooks/ScrollToSection"; // Ejemplo de un componente adicional

// ------------------------------------------------------------------
//                       DEFINICIÓN DEL COMPONENTE
// ------------------------------------------------------------------
/**
 * Componente principal que gestiona las rutas de la aplicación.
 *
 * Este componente define las siguientes rutas para:
 * - `/`: Carga el componente de inicio (Home).
 * - `/SobreNosotros`: Carga el componente SobreNosotros (Sobre nosotros).
 * - `*`: Muestra un mensaje de 404 para rutas no encontradas.
 *
 * @returns {JSX.Element} El router con las rutas definidas para la aplicación.
 */
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Ruta para la página principal (Home) */}
        <Route path="/" element={<Home />} />

        {/* Ruta para la página "Sobre nosotros" */}
        <Route path="/sobre-nosotros" element={<SobreNosotros />} />

        {/* Ruta para manejar rutas no encontradas (404) */}
        <Route path="*" element={<h1>404 - Página no encontrada</h1>} />
      </Routes>
    </Router>
  );
}

// ------------------------------------------------------------------
//                            EXPORTS
// ------------------------------------------------------------------
// Exportación del componente principal para ser utilizado en otras partes de la aplicación.

export default App;
