import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function MenuBurger({vBurgerActiva, setvBurgerActiva}) {

    const [vPosicionX, setVPosicionX] = useState(-100)
    const navigate = useNavigate(); // Hook para redirigir al usuario

    useEffect(() => {
      if (vBurgerActiva) {
        setVPosicionX(0)
    } else {
          setVPosicionX(-100)
        
      }
    }, [vBurgerActiva])
    

  return (
    <div class="menu-movil" style={{ transform: `translate(${vPosicionX}%, 0px)` }}>
      <div class="menu-movil-titulo">
        <img
          width="105"
          height="22.647037506103516"
          alt=""
          src="images/logo-cloudih-principal.png"
          loading="lazy"
          class="vectors-wrapper-3"
        />
        <div class="x-1" onClick={()=>setvBurgerActiva(false)}>
          <i class="fi fi-rr-cross-small vectors-wrapper-4"></i>
        </div>
      </div>
      <div class="menu-movil-info">
        <div class="menu-apartados" onClick={()=>navigate("/")}>
          <div class="boton-menu-movil-apartados-texto">Home</div>
          <div class="flechas-arriba-abajo-2">
            <i class="fi fi-rr-angle-small-down vectors-wrapper-5"></i>
          </div>
        </div>
        <div class="menu-apartados" onClick={()=>navigate("/sobre-nosotros")}>
          <div class="boton-menu-movil-apartados-texto">Nosotros</div>
          <div class="flechas-arriba-abajo-2">
            {" "}
            <i class="fi fi-rr-angle-small-down vectors-wrapper-5"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
