import React from "react";

export default function SectPlanAccion() {
  return (
    <div class="nosotros-plan-accion">
      <div class="linea-divisora-verde"></div>
      <img
        src="images/reportbuilderrr.png"
        loading="lazy"
        sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 97vw"
        srcset="images/reportbuilderrr.png 500w, images/reportbuilderrr.png 800w, images/reportbuilderrr.png 1080w, images/reportbuilderrr.png 1600w, images/reportbuilderrr.png 2000w, images/reportbuilderrr.png 2600w, images/reportbuilderrr.png 3200w, images/reportbuilderrr.png 7680w"
        alt=""
        class="plan-de-accion-img"
      />
    </div>
  );
}
