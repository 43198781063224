import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function MenuHeader({setvBurgerActiva}) {
    const navigate = useNavigate(); // Hook para redirigir al usuario

  return (
    <div data-figma-id="306:574" class="menu">
        <div data-figma-id="306:548" class="menu-2">
          <i class="fi fi-rs-burger-menu img-5" onClick={()=>setvBurgerActiva(true)}></i>
          <div data-figma-id="333:510" class="div-block" onClick={()=>navigate("/")}>
            <img
              src="images/logo-cloudih-principal.png"
              data-figma-id="333:511"
              
            />
          </div>
          <div data-figma-id="306:550" class="menu-info">
            <div data-figma-id="333:495" class="menu-info-1" onClick={()=>navigate("/")}>
              <p data-figma-id="333:496" class="menu-info-1-texto">
                Home
              </p>
              
            </div>
            <div data-figma-id="333:501" class="menu-info-1" onClick={()=>navigate("/sobre-nosotros")}>
              <p data-figma-id="333:502" class="menu-info-1-texto">
                Nosotros
              </p>
              
            </div>
          </div>
          <div data-figma-id="306:555" class="menu-botones">
            <i class="fi fi-ss-circle-phone img-3"></i>
            <div data-figma-id="306:576" class="boton-contactanos">
              <p data-figma-id="306:577" class="boton-contactanos-texto">
                Contáctanos
              </p>
            </div>
          </div>
        </div>
      </div>
  )
}
