import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function SectLoQueHacemos() {

  const navigate = useNavigate(); // Hook para redirigir al usuario

  return (
    <div data-figma-id="336:523" class="home-info-2">
        <div data-figma-id="336:513" class="home-info-2-contenido-1">
          <p data-figma-id="336:514" class="home-info-2-contenido-1-texto">
            Lo que hacemos
          </p>
          <i class="fi fi-rs-arrow-right img-2"></i>
        </div>
        <div data-figma-id="336:516" class="home-info-2-contenido-2">
          <p data-figma-id="336:517" class="home-info-2-contenido-2-texto">
            Cloudih es una empresa de servicios en la nube que ayuda a las
            organizaciones a prosperar en un mundo centrado en la tecnología.
          </p>
          <div data-figma-id="336:518" class="home-info-2-boton" onClick={()=>navigate("/sobre-nosotros")}>
            <i class="fi fi-ss-arrow-circle-right img-6"></i>
            <p data-figma-id="336:520" class="home-info-2-boton-texto">
              MÁS SOBRE NOSOTROS
            </p>
          </div>
        </div>
      </div>
  )
}
