import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./assets/css/cloudih-pagina.webflow.css";
import "./assets/css/styleCustomGeneral.css";
import "./assets/css/normalize.css";
import "./assets/css/webflow.css";
import { WindowSizeProvider } from "./WindowSizeContext";

//import "./assets/css/"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WindowSizeProvider>
      <App />
    </WindowSizeProvider>
  </React.StrictMode>
);
