import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function SectFooter({ targetSectionId, targetPage }) {

  const navigate = useNavigate();
    const location = useLocation();

    const handleScrollOrNavigate = () => {
        if (location.pathname === targetPage) {
            // Ya estamos en la página objetivo, desplazar a la sección
            const element = document.getElementById(targetSectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Cambiar a la página objetivo y desplazar a la sección
            navigate(`${targetPage}#${targetSectionId}`);
        }
    };

  return (
    <div data-figma-id="354:791" class="sect-footer">
    <div data-figma-id="354:749" class="id-m42ay1xj166126-footer-block">
      <div class="div-block-9">
        <div class="div-block-10">
          <p data-figma-id="354:750" class="id-m42ay1xh807512">Acerca de</p><i class="fi fi-rr-angle-small-down img-30"></i>
        </div>
        <div data-figma-id="354:751" class="id-m42ay1xj033641-divider"></div>
      </div>
      <div data-figma-id="354:752" class="id-m42ay1xj494077-footer-list" onClick={handleScrollOrNavigate}>
        <p data-figma-id="354:753" class="id-m42ay1xl502436-con-hover">Nuestro Equipo de Trabajo</p>
      </div>
    </div>
    <div data-figma-id="354:756" class="id-m42ay1xk776884-footer-block">
      <div class="div-block-9">
        <div class="div-block-10">
          <p data-figma-id="354:750" class="id-m42ay1xh807512">Nuestros Servicios</p><i class="fi fi-rr-angle-small-down img-30"></i>
        </div>
        <div data-figma-id="354:751" class="id-m42ay1xj033641-divider"></div>
      </div>
      <div data-figma-id="354:759" class="id-m42ay1xk816979-footer-list">
        <p data-figma-id="354:760" class="id-m42ay1xl502436">Gen AI &amp; LLM’s</p>
        <p data-figma-id="354:761" class="id-m42ay1xl502436">FinOps</p>
        <p data-figma-id="354:762" class="id-m42ay1xl502436">Report Builder</p>
        <p data-figma-id="354:763" class="id-m42ay1xl502436">Lakehouse</p>
        <p data-figma-id="354:764" class="id-m42ay1xl502436">MLOps</p>
        <p data-figma-id="354:765" class="id-m42ay1xl502436">UX/UI Development</p>
      </div>
    </div>
    <div data-figma-id="354:766" class="id-m42ay1xl049939-footer-block">
      <div class="div-block-9">
        <div class="div-block-10">
          <p data-figma-id="354:750" class="id-m42ay1xh807512">Frameworks</p><i class="fi fi-rr-angle-small-down img-30"></i>
        </div>
        <div data-figma-id="354:751" class="id-m42ay1xj033641-divider"></div>
      </div>
      <div data-figma-id="354:769" class="id-m42ay1xl476245-footer-list">
        <p data-figma-id="354:770" class="id-m42ay1xl502436">PowerBi</p>
        <p data-figma-id="354:771" class="id-m42ay1xl502436">OpenAI</p>
        <p data-figma-id="354:772" class="id-m42ay1xl502436">MLops</p>
        <p data-figma-id="354:773" class="id-m42ay1xl502436">React</p>
        <p data-figma-id="354:774" class="id-m42ay1xl502436">GitHub</p>
        <p data-figma-id="354:775" class="id-m42ay1xl942667-ver-m-s">Ver más</p>
      </div>
    </div>
    <div data-figma-id="354:776" class="id-m42ay1xo871628-footer-block">
      <div class="div-block-9">
        <div class="div-block-10">
          <p data-figma-id="354:750" class="id-m42ay1xh807512">Contacto</p><i class="fi fi-rr-angle-small-down img-30"></i>
        </div>
        <div data-figma-id="354:751" class="id-m42ay1xj033641-divider"></div>
      </div>
      <div data-figma-id="354:779" class="id-m42ay1xo707399-footer-list">
        <p data-figma-id="354:780" class="id-m42ay1xl502436">juan.pulgarin@cloudih.io</p>
        <p data-figma-id="354:781" class="id-m42ay1xl502436">+52 55 4157 6244</p>
        <div data-figma-id="354:782" class="id-m42ay1xo344375-social-links"><img src="images/SocialFacebook.svg" data-figma-id="354:792" class="img-11"/><img src="images/SocialInstagram.svg" data-figma-id="354:794" class="img-12"/><img src="images/SocialTwitter.svg" data-figma-id="354:796" class="img-13"/></div>
      </div>
    </div>
  </div>
  )
}
