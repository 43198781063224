import React, { useEffect } from "react";
import MenuHeader from '../../../../components/MenuHeader'

import "particles.js";
import { useNavigate } from "react-router-dom";

export default function SectHeader({setvBurgerActiva}) {
  useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        move:{
          speed:0.5
        },
        number: {
          value: 120,
          density: {
            enable: true,
            value_area: 1000,
          },
        },
        color: {
          value: ["#435346"],
        },

        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#435346",
          },
          polygon: {
            nb_sides: 1,
          },
        },
        opacity: {
          value: 1,
          random: true,
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0.5,
            sync: false,
          },
        },
        size: {
          value: 5,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 120,
          color: "#435346",
          opacity: 1,
          width: 3,
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: false,
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  const handleScroll = () => {
    const element = document.getElementById('sec-nosotros');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // Desplaza suavemente
    }
};

  return (
    <div data-figma-id="306:575" class="home-info-1-header" id="particles-js">
      <MenuHeader setvBurgerActiva={setvBurgerActiva}/>
      <div data-figma-id="306:516" class="home-info-1-publicidad">
        <p data-figma-id="306:517" class="home-info-1-publicidad-texto">
          La empresa Cloud que marca la diferencia
        </p>
        <div data-figma-id="19:382" class="boton-conoce-mas" onClick={handleScroll}>
          <p data-figma-id="19:380" class="boton-conoce-mas-texto">
            Conoce más
          </p>
        </div>
      </div>
      
    </div>
  );
}
