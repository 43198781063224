import React from "react";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const OrbitingCircles = ({ duracion = 300 }) => {
  return (
    <>
      {/* Contenedor de órbita */}
      <motion.div
        style={{
          position: "relative",
          display: "flex",
          width: "auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gridRowGap: "10px",
          textDecoration: "none",
          border: "1px solid #69B5AE",
          width: "150px", // Tamaño de la órbita
          height: "150px",
          borderRadius: "50%",
        }}
        animate={{ rotate: 360 }}
        transition={{
          repeat: Infinity,
          duration: duracion,
          ease: "linear",
        }}
      >
        {/* Imagen 1: Arriba */}
        <motion.img
          src="images/Frame-89.png"
          alt="Imagen Arriba"
          style={{
            position: "absolute",
            left: "67%",
            top: "0%",
            right: "0%",
            bottom: "auto",
            display: "flex",
            width: "50px",
            height: "50px",
            justifyContent: "flex-start",
            alignItems: "center",
            gridColumnGap: "10px",
            textDecoration: "none",
          }}
          animate={{ rotate: -360 }}
          transition={{
            repeat: Infinity,
            duration: duracion,
            ease: "linear",
          }}
        />

        {/* Imagen 2: Derecha */}
        <motion.img
          src="images/Frame-95.png"
          alt="Imagen Derecha"
          style={{
            position: "absolute",
            left: "0%",
            top: "67%",
            right: "0%",
            bottom: "auto",
            display: "flex",
            width: "50px",
            height: "50px",
            justifyContent: "flex-start",
            alignItems: "center",
            gridColumnGap: "10px",
            textDecoration: "none",
          }}
          animate={{ rotate: -360 }}
          transition={{
            repeat: Infinity,
            duration: duracion,
            ease: "linear",
          }}
        />

        {/* Imagen 3: Abajo */}
        <motion.img
          src="images/Frame-96.png"
          alt="Imagen Abajo"
          style={{
            position: "absolute",
            left: "67%",
            top: "67%",
            right: "0%",
            bottom: "auto",
            display: "flex",
            width: "50px",
            height: "50px",
            justifyContent: "flex-start",
            alignItems: "center",
            gridColumnGap: "10px",
            textDecoration: "none",
          }}
          animate={{ rotate: -360 }}
          transition={{
            repeat: Infinity,
            duration: duracion,
            ease: "linear",
          }}
        />

        {/* Imagen 4: Izquierda */}
        <motion.img
          src="images/Frame-97.png"
          alt="Imagen Izquierda"
          style={{
            position: "absolute",
            left: "0%",
            top: "0%",
            right: "0%",
            bottom: "auto",
            display: "flex",
            width: "50px",
            height: "50px",
            justifyContent: "flex-start",
            alignItems: "center",
            gridColumnGap: "10px",
            textDecoration: "none",
          }}
          animate={{ rotate: -360 }}
          transition={{
            repeat: Infinity,
            duration: duracion,
            ease: "linear",
          }}
        />
      </motion.div>
    </>
  );
};

export default function SectNosotros() {
  const navigate = useNavigate(); // Hook para redirigir al usuario

  return (
    <div data-figma-id="351:801" class="home-info-4-nosotros" id="sec-nosotros" >
      <div data-figma-id="347:1139" class="frame-94">
        <div data-figma-id="347:1136" class="frame-30">
          <p data-figma-id="347:1137" class="servicios-titulo-2">
            Nosotros
          </p>
          <p
            data-figma-id="347:1138"
            class="error-a1f1c374-ba28-f9c5-8184-0574815fb66b"
          >
            En Cloudih desarrollamos aplicativos personalizados basados en
            microservicios y módulos adaptables, diseñados para integrarse
            perfectamente en cualquier entorno y ajustarse a las necesidades
            específicas de cada cliente.
          </p>
        </div>
        <div data-figma-id="347:1140" class="frame-90">
          <div data-figma-id="347:1141" class="frame-87"></div>
          <div data-figma-id="347:1142" class="frame-81">
            <img src="images/Group.svg" data-figma-id="347:1143" />
            <div data-figma-id="347:1145" class="frame-100">
              <div data-figma-id="347:1146" class="frame-82">
                <p data-figma-id="347:1147" class="experiencia-en-la-nube">
                  Experiencia en la nube
                </p>
                <p
                  data-figma-id="347:1148"
                  class="error-fde33e83-0f7d-d820-7089-405f63c220ac"
                >
                  Desde el principio nos centramos en servir a los clientes para
                  dar calidad en el producto.
                </p>
              </div>
              <div data-figma-id="347:1149" class="frame-99">
                <img
                  src="images/Google-Cloud-Logo-PNG-Isolated-File-1.png"
                  data-figma-id="347:1150"
                />
                <img
                  src="images/aws-1869025-1583149-1.png"
                  data-figma-id="347:1151"
                />
                <img
                  src="images/MicrosoftAzure-1.png"
                  data-figma-id="347:1152"
                />
              </div>
            </div>
          </div>
          <div data-figma-id="347:1167" class="frame-101-copy">
            <img
              src="images/personalizar-computadora-1.svg"
              data-figma-id="347:1168"
            />
            <div data-figma-id="347:1170" class="frame-82">
              <p data-figma-id="347:1171" class="liderazgo-en-dise-o">
                Liderazgo en Diseño{" "}
              </p>
              <p
                data-figma-id="347:1172"
                class="error-5a5a2c81-e274-8f31-e281-bcfa259957e4"
              >
                Somos un verdadero socio de diseño y lo guiamos a través del
                proceso.
              </p>
            </div>
          </div>
          <div data-figma-id="347:1153" class="frame-87"></div>
        </div>
        <div data-figma-id="347:1154" class="frame-90">
          <div data-figma-id="347:1155" class="frame-87"></div>
          <div data-figma-id="347:1156" class="frame-78">
            <img src="images/talento-1.svg" data-figma-id="347:1157" />
            <div data-figma-id="347:1159" class="frame-82">
              <p data-figma-id="347:1160" class="talento-excepcional">
                Talento Excepcional
              </p>
              <p
                data-figma-id="347:1161"
                class="trabajamos-en-productos-complejos-es-por-eso-que-cada-proyecto-es-personalizado"
              >
                Trabajamos en productos complejos, es por eso que cada proyecto
                es personalizado
              </p>
            </div>
          </div>
          <div data-figma-id="347:1162" class="frame-79">
            <div data-figma-id="347:1163" class="frame-90-centro">
              <p
                data-figma-id="347:1164"
                class="m-s-sobre-la-historia-de-cloudih"
              >
                Más sobre la historia de Cloudih
              </p>
              <div
                data-figma-id="347:1165"
                class="frame-83"
                onClick={() => navigate("/sobre-nosotros")}
              >
                <p data-figma-id="347:1166" class="conoce-nuestra-historia">
                  Conoce Nuestra Historia
                </p>
              </div>
            </div>
          </div>
          <div data-figma-id="347:1167" class="frame-101">
            <img
              src="images/personalizar-computadora-1.svg"
              data-figma-id="347:1168"
            />
            <div data-figma-id="347:1170" class="frame-82">
              <p data-figma-id="347:1171" class="liderazgo-en-dise-o">
                Liderazgo en Diseño{" "}
              </p>
              <p
                data-figma-id="347:1172"
                class="error-5a5a2c81-e274-8f31-e281-bcfa259957e4"
              >
                Somos un verdadero socio de diseño y lo guiamos a través del
                proceso.
              </p>
            </div>
          </div>
          <div data-figma-id="347:1176" class="frame-78-copy">
            <img
              src="images/video-conferencia-1.svg"
              data-figma-id="347:1177"
            />
            <div data-figma-id="347:1179" class="frame-85">
              <div data-figma-id="347:1180" class="frame-82">
                <p data-figma-id="347:1181" class="comunicaci-n-impecable">
                  Comunicación Impecable
                </p>
                <p
                  data-figma-id="347:1182"
                  class="error-679d74c4-b7c2-73a6-e949-8267e2f41e33"
                >
                  Creemos que la comunicación es la parte más importante de
                  cualquier proyecto.{" "}
                </p>
              </div>
              <OrbitingCircles />
            </div>
          </div>
          <div data-figma-id="347:1173" class="frame-87"></div>
        </div>
        <div data-figma-id="347:1174" class="frame-90-copy">
          <div data-figma-id="347:1175" class="frame-87"></div>
          <div data-figma-id="347:1176" class="frame-78">
            <img
              src="images/video-conferencia-1.svg"
              data-figma-id="347:1177"
            />
            <div data-figma-id="347:1179" class="frame-85">
              <div data-figma-id="347:1180" class="frame-82">
                <p data-figma-id="347:1181" class="comunicaci-n-impecable">
                  Comunicación Impecable
                </p>
                <p
                  data-figma-id="347:1182"
                  class="error-679d74c4-b7c2-73a6-e949-8267e2f41e33"
                >
                  Creemos que la comunicación es la parte más importante de
                  cualquier proyecto.{" "}
                </p>
              </div>
              <OrbitingCircles />
            </div>
          </div>
          <div data-figma-id="347:1189" class="frame-87"></div>
        </div>
      </div>
      <div data-figma-id="347:1139" class="frame-94-copy">
        <div data-figma-id="347:1136" class="frame-30">
          <p data-figma-id="347:1137" class="servicios-titulo-2">
            Nosotros
          </p>
          <p
            data-figma-id="347:1138"
            class="error-a1f1c374-ba28-f9c5-8184-0574815fb66b"
          >
            En Cloudih desarrollamos aplicativos personalizados basados en
            microservicios y módulos adaptables, diseñados para integrarse
            perfectamente en cualquier entorno y ajustarse a las necesidades
            específicas de cada cliente.
          </p>
        </div>
        <div data-figma-id="347:1140" class="frame-90">
          <div data-figma-id="347:1142" class="frame-81">
            <img src="images/Group.svg" data-figma-id="347:1143" />
            <div data-figma-id="347:1145" class="frame-100">
              <div data-figma-id="347:1146" class="frame-82">
                <p data-figma-id="347:1147" class="experiencia-en-la-nube">
                  Experiencia en la nube
                </p>
                <p
                  data-figma-id="347:1148"
                  class="error-fde33e83-0f7d-d820-7089-405f63c220ac"
                >
                  Desde el principio nos centramos en servir a los clientes para
                  dar calidad en el producto.
                </p>
              </div>
              <div data-figma-id="347:1149" class="frame-99">
                <img
                  src="images/Google-Cloud-Logo-PNG-Isolated-File-1.png"
                  data-figma-id="347:1150"
                />
                <img
                  src="images/aws-1869025-1583149-1.png"
                  data-figma-id="347:1151"
                />
                <img
                  src="images/MicrosoftAzure-1.png"
                  data-figma-id="347:1152"
                />
              </div>
            </div>
          </div>
          <div data-figma-id="347:1167" class="frame-101-copy">
            <img
              src="images/personalizar-computadora-1.svg"
              data-figma-id="347:1168"
            />
            <div data-figma-id="347:1170" class="frame-82">
              <p data-figma-id="347:1171" class="liderazgo-en-dise-o">
                Liderazgo en Diseño{" "}
              </p>
              <p
                data-figma-id="347:1172"
                class="error-5a5a2c81-e274-8f31-e281-bcfa259957e4"
              >
                Somos un verdadero socio de diseño y lo guiamos a través del
                proceso.
              </p>
            </div>
          </div>
        </div>
        <div data-figma-id="347:1154" class="frame-90">
          <div data-figma-id="347:1162" class="frame-79">
            <div data-figma-id="347:1163" class="frame-90-centro">
              <p
                data-figma-id="347:1164"
                class="m-s-sobre-la-historia-de-cloudih"
              >
                Más sobre la historia de Cloudih
              </p>
              <div
                data-figma-id="347:1165"
                class="frame-83"
                onClick={() => navigate("/sobre-nosotros")}
              >
                <p data-figma-id="347:1166" class="conoce-nuestra-historia">
                  Conoce Nuestra Historia
                </p>
              </div>
            </div>
          </div>
          <div data-figma-id="347:1156" class="frame-78">
            <img src="images/talento-1.svg" data-figma-id="347:1157" />
            <div data-figma-id="347:1159" class="frame-82">
              <p data-figma-id="347:1160" class="talento-excepcional">
                Talento Excepcional
              </p>
              <p
                data-figma-id="347:1161"
                class="trabajamos-en-productos-complejos-es-por-eso-que-cada-proyecto-es-personalizado"
              >
                Trabajamos en productos complejos, es por eso que cada proyecto
                es personalizado
              </p>
            </div>
          </div>
          <div data-figma-id="347:1167" class="frame-101">
            <img
              src="images/personalizar-computadora-1.svg"
              data-figma-id="347:1168"
            />
            <div data-figma-id="347:1170" class="frame-82">
              <p data-figma-id="347:1171" class="liderazgo-en-dise-o">
                Liderazgo en Diseño{" "}
              </p>
              <p
                data-figma-id="347:1172"
                class="error-5a5a2c81-e274-8f31-e281-bcfa259957e4"
              >
                Somos un verdadero socio de diseño y lo guiamos a través del
                proceso.
              </p>
            </div>
          </div>
          <div data-figma-id="347:1176" class="frame-78-copy">
            <img
              src="images/video-conferencia-1.svg"
              data-figma-id="347:1177"
            />
            <div data-figma-id="347:1179" class="frame-85">
              <div data-figma-id="347:1180" class="frame-82">
                <p data-figma-id="347:1181" class="comunicaci-n-impecable">
                  Comunicación Impecable
                </p>
                <p
                  data-figma-id="347:1182"
                  class="error-679d74c4-b7c2-73a6-e949-8267e2f41e33"
                >
                  Creemos que la comunicación es la parte más importante de
                  cualquier proyecto.{" "}
                </p>
              </div>
              <OrbitingCircles />
            </div>
          </div>
        </div>
        <div data-figma-id="347:1174" class="frame-90-copy">
          <div data-figma-id="347:1175" class="frame-87"></div>
          <div data-figma-id="347:1176" class="frame-78">
            <img
              src="images/video-conferencia-1.svg"
              data-figma-id="347:1177"
            />
            <div data-figma-id="347:1179" class="frame-85">
              <div data-figma-id="347:1180" class="frame-82">
                <p data-figma-id="347:1181" class="comunicaci-n-impecable">
                  Comunicación Impecable
                </p>
                <p
                  data-figma-id="347:1182"
                  class="error-679d74c4-b7c2-73a6-e949-8267e2f41e33"
                >
                  Creemos que la comunicación es la parte más importante de
                  cualquier proyecto.{" "}
                </p>
              </div>
              <OrbitingCircles />
            </div>
          </div>
          <div data-figma-id="347:1189" class="frame-87"></div>
        </div>
      </div>
    </div>
  );
}
