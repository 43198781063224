import React, { useEffect, useState } from "react";

import SectHeader from "./components/SectHeader";
import SectLoQueHacemos from "./components/SectLoQueHacemos";
import SectNuestroClientes from "./components/SectNuestroClientes";
import SectNuestrosServicios from "./components/SectNuestrosServicios";
import SectNosotros from "./components/SectNosotros";
import SectFrameworks from "./components/SectFrameworks";
import SectTestimonios from "./components/SectTestimonios";
import SectFooter from "../../components/SectFooter";
import MenuBurger from "../../components/MenuBurger";

export default function Home() {
  const [vBurgerActiva, setvBurgerActiva] = useState(false);

  useEffect(() => {
    const body = document.body;
    if (vBurgerActiva) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }
  }, [vBurgerActiva]);

  return (
    <div className="home">
      <MenuBurger
        vBurgerActiva={vBurgerActiva}
        setvBurgerActiva={setvBurgerActiva}
      />
      <SectHeader setvBurgerActiva={setvBurgerActiva} />
      <SectLoQueHacemos />
      <SectNuestroClientes />
      <SectNuestrosServicios />
      <SectNosotros />
      <SectFrameworks />
      <SectTestimonios />
      <SectFooter targetSectionId="sec-equipo" targetPage="/sobre-nosotros" />
    </div>
  );
}
