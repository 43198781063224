import React, { useContext, useEffect, useState } from "react";
import { WindowSizeContext } from "../../../../WindowSizeContext";


export default function SectCertificados() {
  var vCertificadosImg = [
    "1642562780078.png",
    "azure-data-engineer-associate-600x600.png",
    "azure-data-fundamentals-600x600.png",
    "azure-network-engineer-associate-600x600.png",
    "azure-security-engineer-associate600x600.png",
    "azure-solutions-architect-expert-600x600.png",
    "azure-virtual-desktop-specialty-600x600.png",
    "blob.png",
    "fabric.png",
    "image (1).png",
    "image (2).png",
    "image (3).png",
    "image.png",
    "image2.png",
    "linkedin_thumb_azure-administrator-associate-600x600.png",
    "R.png",
  ];

  const { width, height } = useContext(WindowSizeContext);
  const [vKey, setvKey] = useState(Date.now)
  useEffect(() => {
    if (766< width < 768) {
      setvKey(Date.now)
    }
    if (768< width < 770) {
      setvKey(Date.now)
    }
  }, [width])

  const mRenderCel = () => {
    return <div key={vKey} data-figma-id="610:2216" class="certificados">
      <div data-figma-id="610:2217" class="certificados-apartados-1">
        <ImgCertificado 
        key={Date.now}
          posicionInicial={0}
          vCertificadosImg={vCertificadosImg}
        />

        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={1}
          vCertificadosImg={vCertificadosImg}
        />

        <div data-figma-id="610:2219" class="rectangle-3"></div>
        
      </div>
      <div data-figma-id="610:2217" class="certificados-apartados-1">
        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={3}
          vCertificadosImg={vCertificadosImg}
        />
        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={4}
          vCertificadosImg={vCertificadosImg}
        />
        
      </div>
    </div>;
  };

  const mRenderNoCel = () => {
    return <div key={vKey} data-figma-id="610:2216" class="certificados">
      <div data-figma-id="610:2217" class="certificados-apartados-1">
        <ImgCertificado key={Date.now}
          posicionInicial={0}
          vCertificadosImg={vCertificadosImg}
        />

        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={1}
          vCertificadosImg={vCertificadosImg}
        />

        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={2}
          vCertificadosImg={vCertificadosImg}
        />

        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={3}
          vCertificadosImg={vCertificadosImg}
        />

        <div data-figma-id="610:2219" class="rectangle-3"></div>
      </div>
      <div data-figma-id="610:2217" class="certificados-apartados-1">
        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={4}
          vCertificadosImg={vCertificadosImg}
        />
        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={5}
          vCertificadosImg={vCertificadosImg}
        />
        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={6}
          vCertificadosImg={vCertificadosImg}
        />
        <div data-figma-id="610:2219" class="rectangle-3"></div>
        <ImgCertificado key={Date.now}
          posicionInicial={7}
          vCertificadosImg={vCertificadosImg}
        />
      </div>
    </div>;
  };

  return (
    <div data-figma-id="610:2235" class="nosotros-certificados">
      <div data-figma-id="576:2298" class="nosotros-certificados-info">
        <div data-figma-id="576:2299" class="nosotros-certificados-textos">
          <p data-figma-id="576:2300" class="nosotros-certificados-texto-1">
            Todo trabajo
          </p>
          <p data-figma-id="576:2301" class="nosotros-certificados-texto-2">
            tiene un esfuerzo y preparación
          </p>
        </div>
      </div>
      {width>991?mRenderNoCel():mRenderCel()}
      <p data-figma-id="576:2303" class="nosotros-certificados-texto-3">
        Conoce nuestras Certificaciones
      </p>
      <div data-figma-id="576:2304" class="linea-divisora-gris"></div>
    </div>
  );
}

function ImgCertificado({ posicionInicial = 0, vCertificadosImg }) {
  const [vPosicionActual, setvPosicionActual] = useState(posicionInicial);
  const [vOpacidad, setVOpacidad] = useState(1);

  useEffect(() => {
    var vIdInterval = setInterval(() => {
      setvPosicionActual((vPrev) => {
        setVOpacidad(0.3);
        setTimeout(() => {
          setVOpacidad(1);
        }, 200);
        if (vPrev + 1 === vCertificadosImg.length) {
          return 0;
        } else {
          return vPrev + 1;
        }
      });
    }, 5200);
    return () => clearInterval(vIdInterval);
  }, []);

  if (vCertificadosImg[vPosicionActual]==="R.png") {
    return <img src={`images/${vCertificadosImg[vPosicionActual]}`} loading="lazy" sizes="(max-width: 991px) 150px, 571px" srcset={`images/${vCertificadosImg[vPosicionActual]} 500w, images/${vCertificadosImg[vPosicionActual]} 571w`} alt="" class="certificado-2"/>
  }

  return (
    <img src={`images/${vCertificadosImg[vPosicionActual]}`} loading="lazy" alt="" class="certificado-1"
      style={{ opacity: vOpacidad }}
    />
  );
}
