import React, { useContext, useState } from "react";
import { WindowSizeContext } from "../../../../WindowSizeContext";

export default function SectInfo3() {
  

  return (
    <div data-figma-id="710:1809" class="nosotros-info-3">
    <div data-figma-id="649:3013" class="mision">
      <p data-figma-id="649:3014" class="mision-m">M</p>
      <div data-figma-id="649:3015" class="mision-contenido">
        <div data-figma-id="649:3016" class="mision-linea"></div>
        <div data-figma-id="649:3017" class="mision-info">
          <p data-figma-id="649:3018" class="mision-info-titulo">Misión</p>
          <p data-figma-id="649:3019" class="mision-info-texto">En Cloudih desarrollamos aplicativos personalizados basados en microservicios y módulos adaptables, diseñados para integrarse perfectamente en cualquier entorno y ajustarse a las necesidades específicas de cada cliente. </p>
        </div>
      </div>
    </div>
    <div data-figma-id="649:3020" class="vision">
      <p data-figma-id="649:3021" class="vision-v">V</p>
      <div data-figma-id="649:3022" class="vision-contenido">
        <div data-figma-id="649:3023" class="vision-linea"></div>
        <div data-figma-id="649:3024" class="vision-info">
          <p data-figma-id="649:3025" class="vision-titulo">Visión</p>
          <p data-figma-id="649:3026" class="vision-titulo-texto">Ser el aliado estratégico preferido por las empresas en su transformación tecnológica, ayudándolas a maximizar su potencial en la nube y alcanzar una optimización eficiente de sus recursos, mientras impulsamos su crecimiento sostenible.</p>
        </div>
      </div>
    </div>
  </div>
  );
}
