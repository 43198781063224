import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SectInfo1 from "./components/SectInfo1";
import SectInfo2 from "./components/SectInfo2";
import SectInfo3 from "./components/SectInfo3";
import SectMetodologias from "./components/SectMetodologias";
import SectPlanAccion from "./components/SectPlanAccion";
import SectRolesEquipo from "./components/SectRolesEquipo";
import SectCertificados from "./components/SectCertificados";
import SecLograrJuntos from "./components/SecLograrJuntos";
import SectInfoValores from "./components/SectInfoValores";
import MenuHeader from "../../components/MenuHeader";

import "particles.js";
import MenuBurger from "../../components/MenuBurger";
import SectFooter from "../../components/SectFooter";

export default function SobreNosotros() {

  const [vBurgerActiva, setvBurgerActiva] = useState(false);

  const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);
  
    useEffect(() => {
      const body = document.body;
      if (vBurgerActiva) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'visible';
      }
    }, [vBurgerActiva]);

  useEffect(() => {
      window.particlesJS("particles-js", {
        particles: {
          move:{
            speed:0.5
          },
          number: {
            value: 120,
            density: {
              enable: true,
              value_area: 1000,
            },
          },
          color: {
            value: ["#435346"],
          },
  
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#435346",
            },
            polygon: {
              nb_sides: 1,
            },
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.5,
              sync: false,
            },
          },
          size: {
            value: 5,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 120,
            color: "#435346",
            opacity: 1,
            width: 3,
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
            onclick: {
              enable: false,
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    }, []);

  return (
    <div class="nosotros-cloudih">
      <MenuBurger
              vBurgerActiva={vBurgerActiva}
              setvBurgerActiva={setvBurgerActiva}
            />
      <div class="nosotros-header" id="particles-js">
        <MenuHeader setvBurgerActiva={setvBurgerActiva} />
      </div>

      <div class="nosotros-contenido">
        <SectInfo1 />
        <div class="nosotros-info-general">
          <SectInfo2 />
          <SectInfo3 />
          <SectInfoValores />
          <SectMetodologias />
          
          <SectPlanAccion />
          <SectRolesEquipo />
          <SectCertificados />
          <SecLograrJuntos />
        </div>
      </div>
      <SectFooter targetSectionId="sec-equipo" targetPage="/sobre-nosotros" />
    </div>
  );
}
