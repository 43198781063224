import React, { useEffect, useRef, useState } from "react";
import "./SectNuestroClientes.css"; // Archivo CSS para estilos

const logos = [
  "images/Clip path group.png",
  "images/logo-gyg.png",
  "images/logo-mhm.png",
  "images/logo-zurich.png",
  "images/logo-santander.png",
];

export default function SectNuestroClientes() {
  const divBlockRef = useRef(null);
  const carouselTrackRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const divBlock = divBlockRef.current;
      const carouselTrack = carouselTrackRef.current;
      
      if (divBlock && carouselTrack) {
        const numberOfChildren = carouselTrackRef.current.children.length;
        const isOverflowing =
        (carouselTrack.scrollWidth / numberOfChildren) > divBlock.clientWidth;
        setShouldScroll(isOverflowing);
      }
    };

    checkOverflow();

    // Vuelve a verificar al cambiar el tamaño de la ventana
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [shouldScroll]);

  return (
    <div data-figma-id="337:558" className="home-info-3-publicidad">
      <div data-figma-id="337:543" className="info-publicidad-contenido">
        <div data-figma-id="337:544" className="frame-30-satisfechos">
          <p
            data-figma-id="337:545"
            className="info-publicidad-contenido-texto-1"
          >
            Nuestros clientes satisfechos definen nuestro éxito
          </p>
        </div>
        <div data-figma-id="337:546" className="frame-28">
          <div data-figma-id="337:547" className="frame-27">
            <div className="div-block-4" ref={divBlockRef}>
              <div
                className={`carousel-track ${shouldScroll ? "scrolling" : ""} `}
                ref={carouselTrackRef}
              >
                {/* Duplicamos los logos para el efecto infinito */}
                {Array.from({ length: shouldScroll ? 4 : 1 }).map(
                  (_, index) => (
                    <div key={index} className="carousel-logos">
                      {logos.map((logo, i) => (
                        <img sizes="224px" srcset={`${logo} 500w, ${logo} 896w`}
                        alt={`logo-${i}`} src={logo} loading="lazy" class="logo-cliente"/>
                        
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="difuminacion-i-clientes-satisfechos"></div>
            <div className="difuminacion-d-clientes-satisfechos"></div>
          </div>
          <div data-figma-id="337:553" className="rectangle-1"></div>
        </div>
      </div>
    </div>
  );
}
