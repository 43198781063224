import React, { useEffect } from 'react'
import { useState } from 'react'

export default function SectInfoValores() {

  const [vValor, setVValor] = useState("")
  

  var vValores=[
    "1- Colaboración",
    "2- Impecabilidad",
    "3- Conocimiento",
    "4- Innovación",
    "5- Transparencia",
  ]

  useEffect(() => {
    var vIndex=0
    setVValor(vValores[vIndex])
    var vIdInterval=setInterval(() => {
      if (vIndex>=vValores.length) {
        vIndex=0
      }
      setVValor(vValores[vIndex])
      vIndex+=1
    }, 3000);

    return ()=>clearInterval(vIdInterval)
  }, [])
  

  return (
    <div class="nosotros-info-valores"><img src="https://cdn.prod.website-files.com/67473eb74c870de81d2c4680/675a57465a3cb0bcd8e659ce_imagenes-valores.png" loading="lazy" width="960" height="566.6025390625" alt="" class="imagenes-valores"/>
          <div class="nosotros-info-valores-contenido">
            <div class="component-2">
              <div class="text">{vValor}</div>
            </div>
            <div class="nosotros-info-valores-contenido-texto">
              <div class="nosotros-info-valores-contenido-texto"><span class="nosotros-info-valores-contenido-texto-0">Nuestros valores </span><span class="nosotros-info-valores-contenido-texto-1">son el núcleo de nuestra cultura inspirada e impulsan las decisiones que tomamos.</span></div>
            </div>
          </div>
        </div>
  )
}
