import React, { useEffect, useRef, useState } from "react";

export default function SectFrameworks() {
  const containerRef = useRef(null);
  const [items, setItems] = useState([
    { img: "react.svg", data: null },
    { img: "databricks.svg", data: null },
    { img: "docker.svg", data: null },
    { img: "fastapi.svg", data: null },
    { img: "figma.svg", data: null },
    { img: "github.svg", data: null },
    { img: "kubernetes.svg", data: null },
    { img: "mllops.svg", data: null },
    { img: "openai.svg", data: null },
    { img: "powerbi.svg", data: null },
  ]);
  const [vEstaticos, VEstaticos] = useState(true)

  const [isRecalculating, setIsRecalculating] = useState(false); // Estado global para bloquear recalculaciones

if (vEstaticos) {
  return <div data-figma-id="353:859" class="sect-frameworks">
    <div data-figma-id="353:851" class="id-m42ajm2i038988-frame-4">
      <div data-figma-id="353:852" class="id-m42ajm2h982468-frame-2">
        <p data-figma-id="353:853" class="id-m42ajm2g783118-frameworks">Frameworks</p>
        <p data-figma-id="353:854" class="id-m42ajm2h275132-estructura-cloudih">Estructura Cloudih</p>
      </div>
      <div data-figma-id="353:855" class="id-m42ajm2i528560-frame-3">
        <p data-figma-id="353:856" class="id-m42ajm2i645924-explorar-m-s">Explorar más</p>
      </div>
    </div>
    <div data-figma-id="406:793" class="fw-10"><img src="images/fastapi.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-9"><img src="images/mllops.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-8"><img src="images/kubernetes.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-7"><img src="images/docker.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-6"><img src="images/databricks.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-5"><img src="images/react.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-4"><img src="images/powerbi.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-3"><img src="images/github.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-2"><img src="images/figma.svg" data-figma-id="406:791" class="img-14"/></div>
    <div data-figma-id="406:793" class="fw-1"><img src="images/openai.svg" data-figma-id="406:791" class="img-14"/></div>
  </div>
}

  return (
    <div
      data-figma-id="353:859"
      className="sect-frameworks"
      id="frameworks"
      ref={containerRef}
      style={{ position: "relative", width: "100%", height: "500px" }}
    >
      {items.map((vItem, index) => (
        <Child
          key={vItem.img + index}
          vItem={vItem}
          index={index}
          containerRef={containerRef}
          items={items}
          setItems={setItems}
          isRecalculating={isRecalculating}
          setIsRecalculating={setIsRecalculating}
        />
      ))}
      <div data-figma-id="353:851" className="id-m42ajm2i038988-frame-4">
        <div data-figma-id="353:852" className="id-m42ajm2h982468-frame-2">
          <p data-figma-id="353:853" className="id-m42ajm2g783118-frameworks">
            Frameworks
          </p>
          <p
            data-figma-id="353:854"
            className="id-m42ajm2h275132-estructura-cloudih"
          >
            Estructura Cloudih
          </p>
        </div>
        <div data-figma-id="353:855" className="id-m42ajm2i528560-frame-3">
          <p data-figma-id="353:856" className="id-m42ajm2i645924-explorar-m-s">
            Explorar más
          </p>
        </div>
      </div>
    </div>
  );
}

function Child({
  vItem,
  index,
  containerRef,
  items,
  setItems,
  isRecalculating,
  setIsRecalculating,
}) {
  const [position, setPosition] = useState(null);
  const vRefItem = useRef(null);
  const [vOpacidad, setVOpacidad] = useState(0.5);

  // Validar colisiones usando los hijos del contenedor
  const checkCollision = (newData) => {
    const padding = 10;

    // Obtener los hijos actuales del contenedor
    const children = containerRef.current
      ? Array.from(containerRef.current.children)
      : [];

    return children.some((child) => {
      if (child === vRefItem.current) return false; // Ignorar el propio elemento

      const rect2 = child.getBoundingClientRect();
      const rect1 = {
        x: newData.x,
        y: newData.y,
        width: newData.width,
        height: newData.height,
      };
      return (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
      );
    });
  };

  // Generar una nueva posición aleatoria
  const generateRandomPosition = () => {
    if (!containerRef.current) return null;

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;
    const width = 80; // Tamaño fijo
    const height = 80;

    let x, y;
    let attempts = 0;

    do {
      x = Math.random() * (containerWidth - width);
      y = Math.random() * (containerHeight - height);
      attempts++;
    } while (checkCollision({ x, y, width, height }) && attempts < 50);

    if (attempts >= 50) {
      console.warn(
        `No se encontró una posición válida para el elemento ${index}`
      );
    }

    return { x, y, width, height };
  };

  useEffect(() => {
    const recalculatePosition = () => {
      if (!containerRef.current || isRecalculating) return;

      setIsRecalculating(true); // Bloquear a otros hijos

      const newData = generateRandomPosition();

      if (newData) {
        setPosition(newData);

        // Actualizar el estado global
        setItems((prevItems) =>
          prevItems.map((item, i) =>
            i === index ? { ...item, data: newData } : item
          )
        );

        setTimeout(() => {
          setVOpacidad(0.5);
        }, 200);
      }

      setIsRecalculating(false); // Desbloquear a otros hijos
    };

    const vSegMax = 10;
    const vSegMin = 5;

    recalculatePosition();
    const randomInterval =
      Math.floor(Math.random() * (1000 * vSegMax)) + 1000 * vSegMin; // Tiempo aleatorio entre 5 y 10 segundos
    const interval = setInterval(() => {
      setVOpacidad(0);
      setTimeout(() => {
        recalculatePosition();
      }, 250);
    }, randomInterval);

    return () => clearInterval(interval); // Limpiar intervalo al desmontar
  }, [containerRef, index, setItems, isRecalculating]);

  if (position == null) {
    return <></>;
  }

  return (
    <div
      data-figma-id="406:793"
      className="powerbi"
      ref={vRefItem}
      style={{
        left: position?.x || vItem?.x,
        top: position?.y || vItem?.y,
        position: "absolute",
        opacity: vOpacidad,
        width: `${position.width}px`,
        height: `${position.height}px`,
      }}
    >
      <img
        src={`images/${vItem.img}`}
        className="img-14"
        data-figma-id="406:791"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
