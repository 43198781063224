import React from 'react'

export default function SectInfo1() {
  return (
    <div class="nosotros-info-1">
        <div class="nosotros-info-1-titulo-2">
          <div class="nosotros-info-1-titulo-1-2">
            <div class="nosotros-info-1-titulo-1-texto-2">Sobre Nosotros</div><div class="div-block-8"></div>
          </div>
          <div class="nosotros-info-1-titulo-imagen-2">
            <div class="nosotros-info-1-titulo-2-2">
              <div class="nosotros-info-1-titulo-2-texto-2 truncate-2-line">AYUDAMOS A CRECER TU EMPRESA</div>
              <i class="fi fi-rr-arrow-small-down vectors-wrapper-2"></i>
            </div>
            <img src="images/foto-nosotros.jpg" loading="lazy" sizes="(max-width: 479px) 96vw, (max-width: 767px) 95vw, (max-width: 991px) 90vw, (max-width: 1279px) 92vw, 93vw" srcset="images/foto-nosotros.jpg 500w, images/foto-nosotros.jpg 800w, images/foto-nosotros.jpg 1080w, images/foto-nosotros.jpg 1600w, images/foto-nosotros.jpg 2000w, images/foto-nosotros.jpg 2600w, images/foto-nosotros.jpg 3200w, images/foto-nosotros.jpg 4898w" alt="" class="imagen-principal-2"/>
          </div>
        </div>
      </div>
  )
}
