import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SectTestimonios.css";

export default function SectTestimonios() {
  return (
    <div data-figma-id="354:747" class="sect-testimonios">
      <div data-figma-id="354:715" class="id-m42asw5a420374-frame-41">
        <div data-figma-id="354:716" class="id-m42asw5a931306-frame-31">
          <p data-figma-id="354:717" class="id-m42asw4v017482-testimonios">
            Testimonios
          </p>
        </div>
        <div data-figma-id="354:718" class="id-m42asw5a500713-frame-32">
          <p
            data-figma-id="354:719"
            class="id-m42asw5a522165-no-conf-es-solo-en-nuestra-palabra"
          >
            No confíes solo en nuestra palabra
          </p>
          <p
            data-figma-id="354:720"
            class="id-m42asw5a035937-la-prueba-est-en-la-plataforma"
          >
            La prueba está en la plataforma
          </p>
        </div>
      </div>

      <Carousel />
    </div>
  );
}

const Carousel = ({}) => {
  const items = [
    
    {
      titulo: "MHM",
      contenido:
        '"Trabajar con el equipo de Cloudih es una experiencia inigualable. La atención personalizada, el servicio, el conocimiento, la atención al detalle y la disposición de cada uno de sus integrantes hace que cada proyecto sea verdaderamente increíble."',
      nombre_persona: "Jaime Nielsen",
      puesto_persona: "Director de Operaciones",
    },
    {
      titulo: "Klustomer",
      contenido:
        '"Contar con Cloudih, su experiencia y enfoque colaborativo han complementado de manera excepcional el conocimiento de nuestro equipo, permitiéndonos desarrollar soluciones eficientes e innovadoras. Se ha convertido en un socio estratégico que impulsa nuestro crecimiento y nos ayuda a enfrentar con éxito los desafíos del entorno tecnológico."',
      nombre_persona: "Falconeris Miramon",
      puesto_persona: "CEO",
    },
    {
      titulo: "Zurich Santander",
      contenido:
        '“Durante los años de 2023 y 2024 tuve el placer de colaborar con Cloudih en varios proyectos clave para la organización. La experiencia y profesionalismo demostrados por el equipo han sido excepcionales. Su capacidad para comprender las necesidades específicas de la organización y ofrecer soluciones innovadoras y efectivas ha sido fundamental para el éxito de los proyectos. Además, su enfoque proactivo y su compromiso con la calidad nos han brindado una gran confianza en su trabajo.”',
      nombre_persona: "Olinser Meneses",
      puesto_persona: "Arquitecto de Soluciones",
    },
    // Agrega más elementos según sea necesario
  ];

  const divBlockRef = useRef(null);
  const carouselTrackRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const divBlock = divBlockRef.current;
      const carouselTrack = carouselTrackRef.current;

      if (divBlock && carouselTrack) {
        const numberOfChildren = carouselTrackRef.current.children.length;
        const isOverflowing =
          carouselTrack.scrollWidth / numberOfChildren > divBlock.clientWidth;
        setShouldScroll(isOverflowing);
      }
    };

    checkOverflow();

    // Vuelve a verificar al cambiar el tamaño de la ventana
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [shouldScroll]);

  return (
    <div className="id-m42asw5c677491-frame-47" ref={divBlockRef}>
      <div className="box-item-carucel-testimonios">
        <div
          className={`carousel-track-testimonios ${
            shouldScroll ? "scrolling-testimonios" : ""
          }`}
          ref={carouselTrackRef}
        >
          {/* Duplicamos los logos para el efecto infinito */}
          {Array.from({ length: shouldScroll ? 5 : 1 }).map((_, index) => (
            <div key={index} className="carousel-testimonios">
              {items.map((item, i) => (
                <div
                  data-figma-id="354:728"
                  class="id-m42asw5c055075-frame-43"
                  key={i}
                  style={{
                    flex: "0 0 100%",
                    cadaElementoOcupaEl100DelAnchoDelContenedorBoxSizing:
                      "border-box",
                    padding: "20px",
                  }}
                >
                  <p data-figma-id="354:729" class="id-m42asw5b840932-mhm">
                    {item.titulo}
                  </p>
                  <p
                    data-figma-id="354:730"
                    class="id-m42asw5b733632-testimonio-text truncate-4-line"
                  >
                    {item.contenido}
                  </p>
                  <div
                    data-figma-id="354:731"
                    class="id-m42asw5c040636-frame-35"
                  >
                    <p
                      data-figma-id="354:732"
                      class="id-m42asw5c489649-santiago-m"
                    >
                      {item.nombre_persona}
                    </p>
                    <p
                      data-figma-id="354:733"
                      class="id-m42asw5c716303-director-general-de-mhm"
                    >
                      {item.puesto_persona}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div class="degradado-testimonios-izq"></div>
      <div class="degradado-testimonios-der"></div>
    </div>
  );
};
