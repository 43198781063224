import React from 'react'

export default function SectInfo2() {
  return (
    <div data-figma-id="710:1662" class="nosotros-info-2">
          <p data-figma-id="710:1640" class="nosotros-info-2-texto-1">¿Qué nos diferencia?</p>
          <div data-figma-id="710:1641" class="nosotros-info-2-contenido">
            <div data-figma-id="710:1642" class="nosotros-info-2-contenido-1">
              <div data-figma-id="710:1643" class="nosotros-info-2-contenido-1-numero">
                <p data-figma-id="710:1644" class="numero-1-texto">1</p>
              </div>
              <div data-figma-id="710:1645" class="nosotros-info-2-contenido-1-info">
                <p data-figma-id="710:1646" class="nosotros-info-2-contenido-1-info-texto-1">Versatilidad y expertise en múltiples plataformas cloud</p>
                <p data-figma-id="710:1647" class="nosotros-info-2-contenido-1-info-texto-2">Nuestro equipo cuenta con certificaciones y experiencia en las principales plataformas de nube, lo que nos permite diseñar e implementar aplicaciones completas con infraestructuras elásticas, robustas y escalables. Esto nos posiciona como un socio estratégico para ingresar a nuevos mercados o potenciar soluciones ya existentes.</p>
              </div>
            </div>
            <div data-figma-id="710:1648" class="nosotros-info-2-contenido-2">
              <div data-figma-id="710:1649" class="nosotros-info-2-contenido-2-info">
                <p data-figma-id="710:1650" class="nosotros-info-2-contenido-1-info-texto-1">Diseño y desarrollo centrado en UX/UI de vanguardia</p>
                <p data-figma-id="710:1651" class="nosotros-info-2-contenido-1-info-texto-2">Creemos que una gran experiencia de usuario es el núcleo de cualquier solución exitosa. Por ello, diseñamos y desarrollamos interfaces utilizando frameworks modernos, enfoques modulares y arquitecturas asincrónicas. Nuestro enfoque asegura que las aplicaciones no solo sean funcionales, sino también intuitivas y estéticamente impactantes.</p>
              </div>
              <div data-figma-id="710:1652" class="nosotros-info-2-contenido-2-numero">
                <p data-figma-id="710:1653" class="numero-2-texto">2</p>
              </div>
            </div>
            <div data-figma-id="710:1654" class="nosotros-info-2-contenido-3">
              <div data-figma-id="710:1655" class="nosotros-info-2-contenido-3-numero">
                <p data-figma-id="710:1656" class="numero-3-texto">3</p>
              </div>
              <div data-figma-id="710:1657" class="nosotros-info-2-contenido-3-info">
                <p data-figma-id="710:1658" class="nosotros-info-2-contenido-1-info-texto-1">Metodologías ágiles para resultados rápidos y eficientes</p>
                <p data-figma-id="710:1659" class="nosotros-info-2-contenido-1-info-texto-2">Nos enorgullecemos de trabajar con metodologías ágiles que priorizan la entrega rápida de MVPs (productos mínimos viables) y victorias tempranas para nuestros clientes. Nuestro objetivo es generar valor desde el primer momento, asegurando que cada iteración esté alineada con las metas de negocio.</p>
              </div>
            </div>
          </div>
        </div>
  )
}
